import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { LiteYoutubeEmbed } from "react-lite-yt-embed";
import { Tweet } from "react-twitter-widgets";
import PageHeader from "../../components/PageHeader";
import Head from "../../components/Head";
import WideSection from "../../components/WideSection";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <PageHeader title="Problems" mb={0} mdxType="PageHeader">
  <Head title="Problems – Max Stoiber (@mxstbr)" description="A list of problems I encounter relatively frequently that annoy me." mdxType="Head" />
    </PageHeader>
    <p>{`Inspired by `}<a parentName="p" {...{
        "href": "https://wilhelmklopp.com/problems/"
      }}>{`Wilhelm Klopp`}</a>{`, these are some of the problems I'm thinking about. If any of these trigger any thoughts, please `}<a parentName="p" {...{
        "href": "https://twitter.com/mxstbr"
      }}>{`reach out and talk to me`}</a>{` about them — I'd love to chat!`}</p>
    <h2>{`Local dev environments are brittle`}</h2>
    <p>{`Making local dev environments work often requires incantations of unbelievable length. What should the right set of env variables look like? Where do you get those from? How do you start the server, the client and the db and connect them all together? How do you abstract the differences between operating systems? etc. etc. etc.`}</p>
    <p>{`Docker `}<em parentName="p">{`kind of`}</em>{` solves this, but it's really optimized for production usage. It makes a lot of common development tasks unbearably slow. (e.g. installing dependencies, watching files, syncing files from host to volume, etc.) You have to remember to execute commands in the container (`}<inlineCode parentName="p">{`docker-compose exec app yarn add package`}</inlineCode>{`). etc.etc.`}</p>
    <p>{`Fundamentally, Docker is the wrong tool for the job. It works, but it's far from ideal.`}</p>
    <p>{`docker-compose though is really nice: One command to start/stop the local development environment which'll work on any operating system?! Fantastic!`}</p>
    <p>{`Is there a way to get the benefits of Docker (-compose) in a lighter-weight package optimized for development?`}</p>
    <h2>{`Browser DevTools are optimized for debugging, not for development, and are too generic`}</h2>
    <p>{`Browser devtools are a jack of all trades, master of none. They're fine, but they could be much more specific to `}<em parentName="p">{`my`}</em>{` app and setup.`}</p>
    <p>{`ui-devtools.com is a great example of "DevTools specific to my stack" and makes for a fantastic development experience.`}</p>
    <p>{`However, contrast that with the React DevTools: those are only useful for debugging. UI Devtools, on the other hand, is useful for `}<em parentName="p">{`development`}</em>{`.`}</p>
    <p>{`My first intuition was that it's because UI Devtools writes out to my code, but really that's not the point. It could just as well not do that and still be super useful.`}</p>
    <p>{`What makes UI Devtools useful for development is its ability to scope itself to `}<em parentName="p">{`my app`}</em>{`. I can lightly try different of `}<em parentName="p">{`my styles`}</em>{` on `}<em parentName="p">{`my app`}</em>{` in real-time.`}</p>
    <p>{`Think about this then: how could React DevTools scope itself to my app and be useful for development?`}</p>
    <p>{`What if you could drag and drop any React component that's already in your app onto the page? Or even just edit them in real-time into the JSX tree?`}</p>
    <h2>{`It is impossible to know who owns what in a big company`}</h2>
    <p>{`At GitHub, we had a Slack channel for asking "Who owns this?", and even then it was really hard to figure out how to find who to talk to about a certain thing.`}</p>
    <p>{`On top of that, it's really hard to know what `}<em parentName="p">{`isn't`}</em>{` owned by anyone. What's currently unowned and bitrotting?`}</p>
    <p>{`Could be specific to code, internal tools, or even all processes. Who owns the code review process? Who owns the promotion process? etc. etc.`}</p>
    <h2>{`Making good decisions is really hard`}</h2>
    <p>{`How do you make a good decisions? 🤷‍♂️ It's hard to learn the skill of making good decisions.`}</p>
    <p>{`Might be because the iteration speed is really slow? You can only try one branch, and you can't really figure out if the other one would've worked better or worse — you basically can't A/B test a decision.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      